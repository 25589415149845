@import "src/styles/_variables-mixins.scss";
/* Base imports and resets */
@import './reset';
@import 'material-symbols';
@import 'swiper/css';

/* Color palette - Design system variables */
:root {
  /* Orange palette */
  --mobility-color-orange-50: #fff7ec;
  --mobility-color-orange-100: #ffede4;
  --mobility-color-orange-200: #ffd8a5;
  --mobility-color-orange-300: #ffbc6d;
  --mobility-color-orange-400: #f08301;
  --mobility-color-orange-500: #ff8542;
  --mobility-color-orange-600: #fe5a00;
  --mobility-color-orange-700: #ff5c00;
  --mobility-color-orange-800: #a1320b;
  --mobility-color-orange-900: #822b0c;
  --mobility-color-orange-950: #461304;

  /* Dark palette */
  --mobility-color-dark-50: #f8f8f8;
  --mobility-color-dark-100: #e3e3e3;
  --mobility-color-dark-200: #d1d1d1;
  --mobility-color-dark-300: #b0b0b0;
  --mobility-color-dark-400: #999999;
  --mobility-color-dark-500: #6d6d6d;
  --mobility-color-dark-600: #5d5d5d;
  --mobility-color-dark-700: #4f4f4f;
  --mobility-color-dark-800: #383838;
  --mobility-color-dark-900: #191919;
  --mobility-color-dark-950: #000000;

  /* Light palette */
  --mobility-color-light-50: #ffffff;
  --mobility-color-light-100: #f1f1f1;
  --mobility-color-light-200: #dcdcdc;
  --mobility-color-light-300: #d9d9d9;
  --mobility-color-light-400: #989898;
  --mobility-color-light-500: #7c7c7c;
  --mobility-color-light-600: #656565;
  --mobility-color-light-700: #525252;
  --mobility-color-light-800: #464646;
  --mobility-color-light-900: #3d3d3d;
  --mobility-color-light-950: #292929;

  /* Purple palette */
  --mobility-color-purple-50: #f5f3ff;
  --mobility-color-purple-100: #ece9fe;
  --mobility-color-purple-200: #dccaff;
  --mobility-color-purple-300: #c2b5fd;
  --mobility-color-purple-400: #a38bfa;
  --mobility-color-purple-500: #894eff;
  --mobility-color-purple-600: #6d2dec;
  --mobility-color-purple-700: #6728d9;
  --mobility-color-purple-800: #5621b6;
  --mobility-color-purple-900: #481d95;
  --mobility-color-purple-950: #2b1065;

  /* Utility colors */
  --mobility-color-base-gray: #e9eff5;
  --mobility-color-body-bg-gray: #f4f4f4;

  /* State colors */
  --mobility-color-body-error: #ce2e2e;
  --mobility-color-body-success: #27ae60;
  --mobility-color-body-info: #0075ff;
}

/* Base HTML styles */
html {
  height: 100%;
  font-family: var(--font-outfit);
  font-weight: 500;
  font-size: $fontSizeBase;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/* Body styles */
body {
  min-height: 100vh;
  line-height: 1.5;
  pointer-events: all !important;
  overflow-x: hidden;
  background: var(--mobility-color-light-50);
  color: var(--mobility-color-dark-700);

  &.bg-gray {
    background: var(--mobility-color-body-bg-gray);
  }
}

/* Button styles */
button {
  font-family: var(--font-outfit);
}

/* Container layout system */
.container {
  width: 100%;
  max-width: 1320px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;

  &--no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* Responsive breakpoints */
  @include mediaQuery($xxl) {
    max-width: 1180px;
  }
  @include mediaQuery($xl) {
    max-width: 1140px;
  }
}

/* Typography - Title styles */
.title {
  margin-bottom: 16px;
  color: var(--mobility-color-dark-950);
  @include responsiveFontSize(32px, 28px, 24px);
  line-height: 110%;
  font-weight: 700;

  &__highlight {
    font-weight: 600;
    color: var(--mobility-color-orange-600);
  }

  /* Title size variants */
  &--xsmall {
    @include responsiveFontSize(20px, 16px, 14px);
  }

  &--small {
    @include responsiveFontSize(24px, 18px, 16px);
  }

  &--medium {
    @include responsiveFontSize(36px, 32px, 28px);
  }

  &--large {
    @include responsiveFontSize(40px, 36px, 32px);
  }

  &--xlarge {
    @include responsiveFontSize(48px, 42px, 38px);
    font-weight: 800;
  }

  /* Title color variants */
  &--color-light-50 {
    color: var(--mobility-color-light-50);
  }
  &--color-orange-600 {
    color: var(--mobility-color-orange-600);
  }
}

/* Typography - Subtitle styles */
.subtitle {
  @include responsiveFontSize(24px, 20px, 18px);
  line-height: 150%;
  color: var(--mobility-color-orange-700);
  font-weight: 600;

  /* Subtitle color variants */
  &--color-dark-700 {
    color: var(--mobility-color-dark-700);
  }
  &--color-dark-950 {
    color: var(--mobility-color-dark-950);
  }
}

/* Typography - Text styles */
.text {
  @include responsiveFontSize(16px, 16px, 14px);
  line-height: 150%;
  color: var(--mobility-color-dark-700);
  font-weight: 500;

  &__highlight {
    font-weight: 600;
    color: var(--mobility-color-orange-600);
  }

  &__highlight-alternative {
    font-weight: 600;
    color: var(--mobility-color-purple-600);
  }

  /* Text size variants */
  &--small {
    @include responsiveFontSize(14px, 14px, 14px);
  }
  &--medium {
    @include responsiveFontSize(20px, 16px, 14px);
  }
  &--large {
    @include responsiveFontSize(24px, 18px, 14px);
  }

  /* Text weight variants */
  &--weight-400 {
    font-weight: 400;
  }
  &--weight-700 {
    font-weight: 700;
  }

  /* Text line-height variants */
  &--height-lg {
    line-height: 180%;
  }

  /* Text color variants */
  &--color-400 {
    color: var(--mobility-color-dark-400);
  }
  &--color-light-50 {
    color: var(--mobility-color-light-50);
  }
  &--color-orange-700 {
    color: var(--mobility-color-orange-700);
  }
}

/* Form layout and styling */
.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  &__title {
    margin-bottom: 24px;
    @include responsiveFontSize(16px, 16px, 14px);
    line-height: 150%;
    color: var(--mobility-color-dark-950);
    font-weight: 700;

    &--no-margin {
      margin: 0;
    }
  }

  &__divider {
    width: 100%;
    border-top: 1px solid var(--mobility-color-dark-400);
  }

  /* Form row layout system */
  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;

    & > * {
      width: calc(50% - 12px);
    }

    &--full {
      & > * {
        width: 100%;
      }
    }

    &--col {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > * {
        width: 100%;
      }
    }

    &--no-gap {
      gap: unset;
    }
  }

  &__success-msg {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  /* Responsive form layout */
  @include mediaQuery($lg) {
    &__row {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      & > * {
        width: 100%;
      }

      &--col {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & > * {
          width: 100%;
        }
      }
    }
  }
}

/* Datepicker custom styles */
$datepicker__background-color: #fff !default;
$datepicker__border-color: #ececec !default;
$datepicker__highlighted-color: #a1320b !default;
$datepicker__holidays-color: #ffbc6d !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #fe5a00;
$datepicker__text-color: #292929 !default;
$datepicker__header-color: #292929 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;
$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family:
  'Helvetica Neue', helvetica, arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;

/* Import datepicker styles */
@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';
